import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'app-payment-systems',
  templateUrl: './payment-systems.component.html',
  styleUrls: ['./payment-systems.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgIconComponent]
})
export class PaymentSystemsComponent {
  @Input() noPadding = false;

  public payments = [
    'visa',
    'mastercard-new',
    // 'amex',
    // 'discover',
    'paypal-new',
    'google-pay',
    'apple-pay-new',
  ];

  constructor() { }
}
