<div class="winners">
  <div class="winners__header d-md-none container">
    <h2>Meet our Previous Winners</h2>
    <a [routerLink]="ctaLink">
      <span>View all Winners</span>
      <span>&#8594;</span>
    </a>
  </div>

  <div class="winners__wrapper">
    <div class="winners__header d-none d-md-block">
      <h2>Meet <br> our Previous Winners</h2>
      <a [routerLink]="ctaLink">
        <span>View all Winners</span>
        <svg-icon name="left-arrow"></svg-icon>
      </a>
    </div>

    <div class="winners__winner winners__winner--{{'w-' + (i+1)}}"
         *ngFor="let winner of winners; let i = index;"
         [ngClass]="i % 2 === 0 ? 'winners__winner--odd' : 'winners__winner--even'"
         [ngStyle]="{'background-image': 'url(' + winner.url + ')'}"
    >
    </div>
  </div>

  <div class="winners__bg"></div>
</div>
