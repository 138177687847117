import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { StoreModule } from '@store/store.module';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomPreloaderService } from '@core/services/custom-preloader/custom-preloader.service';
import { TemplateModule } from '@project/shared/template/template.module';
import { HomeModule } from './feature/home/home.module';
import { HomeComponent } from './feature/home/home/home.component';
import { CacheRouteReuseStrategy } from '@core/services/cache-route-reuse-strategy/cache-route-reuse-strategy';
import { TrackingInterceptor } from '@core/services/tracking-interceptor/tracking-interceptor.service';

const appRoutes: Routes | any = [
  {
    path: '',
    component: HomeComponent,
    data: { website: 'p7ds' },
  },
  {
    path: 'faq',
    loadChildren: () => import('./feature/faq/faq.module').then(m => m.FaqModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./feature/about-us/about-us.module').then(m => m.AboutUsModule),
  },
  {
    path: 'streams',
    loadChildren: () => import('./feature/streams/streams.module').then(m => m.StreamsModule),
  },
  {
    path: 'supercar-fest-23',
    loadChildren: () => import('./feature/email-landing/email-landing.module').then(m => m.EmailLandingModule),
    data: { motoevent: 'moto-event-1205' },
  },
  {
    path: 'restricted-country',
    loadChildren: () => import('./feature/restricted-countries/restricted-countries.module').then(m => m.RestrictedCountriesModule),
  },
  {
    path: 'gravity23',
    loadChildren: () => import('./feature/email-landing/email-landing.module').then(m => m.EmailLandingModule),
    data: { motoevent: 'gravity23' },
  },
  {
    path: 'sms-optout/:slug',
    loadChildren: () => import('./feature/sms-optout/sms-optout.module').then(m => m.SmsOptoutModule),
  },
  {
    path: 'sms-optout',
    loadChildren: () => import('./feature/sms-optout/sms-optout.module').then(m => m.SmsOptoutModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./feature/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
  },
  {
    path: 'security',
    loadChildren: () => import('./feature/security/security.module').then(m => m.SecurityModule),
  },
  {
    path: 'cookie-and-privacy-policy',
    loadChildren: () => import('./feature/cookie-and-privacy-policy/cookie-and-privacy-policy.module').then(m => m.CookieAndPrivacyPolicyModule),
  },
  {
    path: 'responsible-gambling',
    redirectTo: 'responsible-playing',
  },
  {
    path: 'responsible-playing',
    loadChildren: () => import('./feature/responsible-gambling/responsible-gambling.module').then(m => m.ResponsibleGamblingModule),
  },
  {
    path: 'website-terms',
    loadChildren: () => import('./feature/website-terms/website-terms.module').then(m => m.WebsiteTermsModule),
  },
  {
    path: 'cart',
    loadChildren: () => import('./feature/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./feature/checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: 'reviews',
    loadChildren: () => import('./feature/reviews/reviews.module').then(m => m.ReviewsModule),
  },
  {
    path: 'my-account',
    loadChildren: () => import('./feature/my-account/my-account.module').then(m => m.MyAccountModule),
  },
  {
    path: 'product/:slug',
    loadChildren: () => import('./feature/product/product.module').then(m => m.ProductModule),
    data: { preloadPriority: 90 },
  },
  {
    path: 'competition-winners/featured',
    loadChildren: () => import('./feature/competition-winners/competition-winners.module').then(m => m.CompetitionWinnersModule),
  },
  {
    path: 'draw-results',
    loadChildren: () => import('./feature/draw-results/draw-results.module').then(m => m.DrawResultsModule),
  },
  {
    path: 'deletion-confirmed',
    loadChildren: () => import('./feature/deletion-confirmed/deletion-confirmed.module').then(m => m.DeletionConfirmedModule),
  },
  {
    path: 'deletion-info',
    loadChildren: () => import('./feature/deletion-info/deletion-info.module').then(m => m.DeletionInfoModule),
  },
  {
    path: 'worldwide-shipping',
    loadChildren: () => import('./feature/worldwide/worldwide.module').then(m => m.WorldwideModule),
  },
  {
    path: 'draw-result/:slug',
    loadChildren: () => import('./feature/draw-result/draw-result.module').then(m => m.DrawResultModule),
  },
  {
    path: 'winner/:slug',
    loadChildren: () => import('./feature/previous-winners/previous-winners.module').then(m => m.PreviousWinnersModule),
  },
  {
    path: 'todays-competitions',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'todays-competitions' },
  },
  {
    path: 'today',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'todays-competitions' },
  },
  {
    path: 'tomorrow',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'tomorrows-competitions' },
  },
  {
    path: 'just-launched',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'just-launched' },
  },
  {
    path: 'monday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'monday-draws' },
  },
  {
    path: 'tuesday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'tuesday-draws' },
  },
  {
    path: 'wednesday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'wednesday-draws' },
  },
  {
    path: 'thursday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'thursday-draws' },
  },
  {
    path: 'friday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'friday-draws' },
  },
  {
    path: 'saturday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'saturday-draws' },
  },
  {
    path: 'sunday',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'sunday-draws' },
  },
  {
    path: 'sunday-draws',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'sunday-draws' },
  },
  {
    path: 'friday-draws',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'friday-draws' },
  },
  {
    path: 'christmas',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'christmas-draws' },
  },
  {
    path: 'nyd',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { slug: 'newyear-draws' },
  },
  {
    path: 'automated-draws-explained',
    loadChildren: () => import('./feature/automated-draws-explained/automated-draws-explained.module').then(m => m.AutomatedDrawsExplainedModule),
  },
  {
    path: ':slug',
    loadChildren: () => import('./feature/category-raffles/category-raffles.module').then(m => m.CategoryRafflesModule),
    data: { preloadPriority: 90 },
  },
  {
    path: 'user-upload/:slug',
    loadChildren: () => import('./feature/user-upload/user-upload.module').then(m => m.UserUploadModule),
  },
  {
    path: 'upload-prompt/:slug',
    loadChildren: () => import('./feature/upload-prompt/upload-prompt.module').then(m => m.UploadPromptModule),
  },
  // {
  //   path: 'prize-acceptance/:slug',
  //   loadChildren: () => import('./feature/prize-acceptance-form/prize-acceptance-form.module').then(m => m.PrizeAcceptanceFormModule),
  // },
  {
    path: 'prize-delivery/:slug',
    loadChildren: () => import('./feature/prize-delivery/prize-delivery.module').then(m => m.PrizeDeliveryModule),
  },

  {
    path: 'basic-refund/:slug',
    loadChildren: () => import('@shared/claim-refund/claim-refund.module').then(m => m.ClaimRefundModule),
  },

  {
    path: '**',
    loadChildren: () => import('./feature/error404/error404.module').then(m => m.Error404Module),
  }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule,
    RouterModule.forRoot(
      appRoutes,
      {
    preloadingStrategy: CustomPreloaderService,
    anchorScrolling: 'enabled'
}
    ),
    CoreModule,
    HomeModule,
    TemplateModule,
  ],
  exports: [
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TrackingInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
