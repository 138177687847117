import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { SettingsState } from '@store/settings/settings.state';
import { Observable, Subject } from 'rxjs';
import { SettingsModel } from '@core/models/settings.model';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-top-availability-message',
  templateUrl: './top-availability-message.component.html',
  styleUrls: ['./top-availability-message.component.scss'],
  standalone: true,
  imports: [CommonModule]
})

export class TopAvailabilityMessageComponent implements OnInit, OnDestroy {
  paymentsDisabledMessage: string | null = null;

  public alive$: Subject<void> = new Subject();

  @Select(SettingsState.settings) settings$: Observable<SettingsModel>;

  constructor() { }

  ngOnInit(): void {
    this.settings$.pipe(takeUntil(this.alive$)).subscribe((settings: SettingsModel) => {
      if (!settings) {
        return;
      }

      if (settings.payments_status == 0 || settings.payments_status == '0') {
        this.paymentsDisabledMessage = settings.payments_disabled_message_homepage;
      }
    });
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
