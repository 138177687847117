export class User {
  id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  display_name: string;
  status: number;
  credits: number;
  referral_credits_income: number;
  monthly_spend_limit: number;
  user_spend_limit: UserSpendLimit | null;
  payments_blocked_until: string;
  refer_key: string;
  register_datetime: string;
  origin: string;
  acquisition: string;
  acquisition_campaign: string;
  status_order: string;
  birth: string;
  notifications: string[];
  accepted_emails: boolean;
  suggested_card_method: string;
  active_user_notifications: UserNotification[];
  registration_brand: string;
  payments_block_type: string;
  scheduled_removal_at: string | null;
  ab_test_seed: number | null;
  flags: string[];
  multiaccount_counter: number;
  monthly_spend_limit_actor: string;
  payments_block_actor: string;
  has_played_iw: boolean;
  has_played: string[];
}

export class UserNotification {
  message: string;
  url: string;
}

export class UserDeletionReason {
  id: number;
  reason: string;
}
export class UserSpendLimit {
  period: string;
  date_from: string;
  date_to: string;
  amount: number;
}

export enum SpendLimitPeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export interface ScheduledMessage {
  id: number;
  user_id: number;
  scheduled_min_at: Date;
  scheduled_max_at: Date;
  trigger_type: string;
  trigger_id: string;
  type: string;
  data1: string;
  data2: string;
  data3: string;
  data4: string;
}

export enum ScheduledMessageType {
  Timeout = 'multiaccount-timeout-all',
  TimeoutIW = 'multiaccount-timeout-iw',
  Spend = 'multiaccount-spend-generic',
  Generic = 'generic'
}

export interface CurrentMonthlySpend {
  monthly_spend: number;
}

export enum ActorType {
  User = 'user',
  Admin = 'admin',
  UserMulti = 'user-multi',
  AdminMulti = 'admin-multi'
}
