export enum RaffleInstantType {
  Regular = 'regular-instant-win',
  Scratch = 'instant-scratch',
  Flip = 'instant-flip',
  Spin = 'instant-spin'
}

export enum RaffleInstantTypeText {
  Regular = 'ticket',
  Scratch = 'card',
  Flip = 'coin',
  Spin = 'ticket'
}

export enum RaffleInstantBadgePageType {
  Card = 'card',
  Product = 'product'
}
