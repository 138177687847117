import { Component, OnInit } from '@angular/core';
import { InitService } from '@core/services/init/init.service';
import { SafePlayService } from '@core/services/safe-play/safe-play.service';
import { projectData } from './project-data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    public initService: InitService,
    private safePlayService: SafePlayService
  ) {
    this.initService.init({ projectData });
  }

  ngOnInit(): void {
    this.safePlayService.handleScheduledMessages(
      'button_border-grey',
      'my-account/safe-playing'
    );
  }
}
