import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SimpleSprintfService {
  constructor() { }

  static sprintf(value: string, ...args: any[]) {
    let valueUpdated = value + '';

    for (const arg of args) {
      valueUpdated = valueUpdated.replace('%s', arg);
    }

    return valueUpdated;
  }
}
