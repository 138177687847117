<app-header></app-header>

<app-top-availability-message></app-top-availability-message>
 
<app-home-slider [slides]="slides" autoplayTimer="8000"></app-home-slider>

<div id="navigation-wrapper" class="navigation-wrapper">
  <div class="raffles-nav-container">
    <app-sticky-nav [items]="rafflesNavItems" [isLoading]="isLoadingNavigation"></app-sticky-nav>
  </div>

  <ng-container *ngFor="let section of competitionSections">
    <section class="section section_gray section_cards"
             *ngIf="section.isAvailable"
             [id]="section.idAttr"
             [class.section_gray]="section.isOdd"
             [class.section_white]="!section.isOdd"
    >
      <div class="container-wide homepage-container">
        <h2 class="section__header">{{section.headingName}}</h2>
        <div class="row justify-content-center">
          <app-raffle-card *ngFor="let raffle of section.raffles" [raffle]="raffle" [version]="section.isOdd ? 'white' : ''"></app-raffle-card>
        </div>
      </div>
    </section>
  </ng-container>
</div>

<app-why-choose-us></app-why-choose-us>
<app-how-to-play></app-how-to-play>
<app-reviews-google></app-reviews-google>
<app-winners-section *ngIf="winners && winners.length" [winners]="winners" [ctaLink]="['/competition-winners/featured']"></app-winners-section>
<app-download-app *ngIf="!isNativeAppVisible"></app-download-app>
<app-payment-systems></app-payment-systems>

<app-footer></app-footer>
