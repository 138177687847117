import { Component, OnInit } from '@angular/core';
import { RaffleSimple } from '@core/models/raffle.model';
import { API_LIST } from '@core/services/web-api/api-list';
import { HttpClient } from '@angular/common/http';

import { NgFor, NgForOf } from '@angular/common';
import { RaffleCardComponent } from '@project/components/raffle-card/raffle-card.component';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  imports: [RaffleCardComponent, NgFor, NgForOf],
  standalone: true
})
export class NotFoundComponent implements OnInit {
  public raffles: RaffleSimple[] = [];

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.loadRaffles();
  }

  loadRaffles() {
    this.httpClient.get(
      API_LIST.getRaffleDraws('GBP', [], 4, false, true, 0, 'date', 'upcoming')
    ).subscribe((raffles: RaffleSimple[]) => {
      this.raffles = raffles;
    });
  }
}
