<div class="loading" *ngIf="isLoading">
  <div class="loading__icon">
    <svg-icon name="spinner" class="spinner"></svg-icon>
  </div>
</div>

<div class="reviews" *ngIf="!isLoading">
  <div class="reviews__header">
    <div class="reviews__identity">
      <div class="reviews__title">7days Performance</div>
<!--      <div class="reviews__location">Rookery Business Park, Norfolk, United Kingdom</div>-->
      <div class="reviews__rating">
        <div class="reviews__rating-average">4.9</div>
        <div class="reviews__stars-box">
          <div class="reviews__stars"></div>
        </div>
      </div>
    </div>

    <div class="reviews__logo">
      <img src="/assets/images/7days/google-reviews-logo.png" />
    </div>
  </div>

  <div class="review" *ngFor="let review of reviews">
    <div class="review__avatar">
      <ng-container *ngIf="review.avatar_url; else avatarInitial">
        <img [src]="review.avatar_url" alt="" class="review__avatar-img" />
      </ng-container>
      <ng-template #avatarInitial>
        <div class="review__avatar-initial" [style.backgroundColor]="review.avatar_color">
          {{review.customer_name | slice:0:1}}
        </div>
      </ng-template>
    </div>

    <div class="review__content">
      <div class="review__name">{{review.customer_name}}</div>
      <div class="review__counter" *ngIf="review.customer_review_counter">
        {{review.customer_review_counter}}
        <ng-container *ngIf="review.customer_review_counter !== 1">reviews</ng-container>
        <ng-container *ngIf="review.customer_review_counter === 1">review</ng-container>
      </div>
      <div class="review__stars-date">
        <div class="review__rating">
          <div class="review__stars" [style.width]="(14 * review.mark) + 'px'"></div>
        </div>
        <div class="review__date">{{review.posted_at|unifyDate|timeAgo}}</div>
      </div>
      <div class="review__text" [innerHTML]="review.text"></div>
    </div>
  </div>
</div>
