import { Component } from '@angular/core';

@Component({
  selector: 'app-how-to-play',
  templateUrl: './how-to-play.component.html',
  styleUrls: ['./how-to-play.component.scss']
})
export class HowToPlayComponent {

  public steps = [
    {
      imageSource: '/assets/svg-icons/prize-gray.svg',
      title: 'Select your prize and entries'
    },
    {
      imageSource: '/assets/svg-icons/answer.svg',
      title: 'Answer the question correctly'
    },
    {
      imageSource: '/assets/svg-icons/microphone.svg',
      title: 'Winner announced on Live Draw'
    },
  ];
}
