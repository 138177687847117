<section class="how">
  <div class="container">
    <h2 class="section-title">How to play</h2>

    <div class="how__steps">

      <div class="how__step">
        <div class="how__icon">
          <img src="/assets/svg-icons/prize-gray.svg" alt="">
        </div>
        <div class="how__label">
          <div class="how__title">01 Step</div>
          <div class="how__subtitle">Select your prize and entries</div>
        </div>
      </div>

      <div class="how__step">
        <div class="how__icon">
          <img src="/assets/svg-icons/answer.svg" alt="">
        </div>
        <div class="how__label">
          <div class="how__title">02 Step</div>
          <div class="how__subtitle">Answer the question correctly</div>
        </div>
      </div>

      <div class="how__step">
        <div class="how__icon">
          <img src="/assets/svg-icons/microphone.svg" alt="">
        </div>
        <div class="how__label">
          <div class="how__title">03 Step</div>
          <div class="how__subtitle">Winner announced on Live Draw</div>
        </div>
      </div>

    </div>
  </div>
</section>
