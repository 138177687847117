import { Injectable } from '@angular/core';
import { BaseTrackingService } from './base-tracking.service';

@Injectable({
  providedIn: 'root'
})
export class SnapchatTrackingService extends BaseTrackingService {

  addHeaders(headers: Record<string, string>): Record<string, string> {
    const scid = this.getCookie('_scid');
    if (scid) {
      headers['X-Scid'] = scid;
    }

    const snapClickId = this.getSnapClickId();
    if (snapClickId) {
      headers['X-Snap-Click-Id'] = snapClickId;
    }

    return headers;
  }

  private getSnapClickId(): string {
    return this.getCookie('snap_click_id') || this.getSnapClickIdFromURL();
  }

  private getSnapClickIdFromURL(): string {
    const urlParams = new URLSearchParams(window.location.search);
    const snapClickId = urlParams.get('snap_click_id');
    if (snapClickId) {
      document.cookie = `snap_click_id=${snapClickId}; path=/;`;
    }
    return snapClickId;
  }
}
