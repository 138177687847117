import { ProjectDataModel } from '../../../project-data.default';

export class ProjectData extends ProjectDataModel {
  project: string = '7days';
  main_domain: string = '7daysperformance.co.uk';
  brand_name_full: string = '7days Performance Ltd';
  prompt_title: string = '7days Performance';
  email_address: string = 'hello@7daysperformance.co.uk';
  phone_number: string = '+443300434841';
  brand_name: string = '7days Performance';
  brand_name_short: string = '7days';
  brand_color_main: string = '#55acee';
  status_bar_color: string = 'ffffff';

  meta_title: string = '7days Performance';
  meta_description: string = '7days Performance';
  og_site_name: string = '7days Performance';
  og_description: string = '7days Performance';
  twitter_theme_color: string = '#55acee';

  product_page_path = 'product';
  cart_page_path = 'cart';

  trustpilot_id = '5d4d2a55c031510001ca8674';
  trustpilot_url = 'https://uk.trustpilot.com/review/7daysperformance.co.uk';
  facebook_url = 'https://www.facebook.com/7daysPerformance';
  facebook_live_videos_url = 'https://www.facebook.com/7daysPerformance/live_videos';
  youtube_url = 'https://www.youtube.com/@7daysperformance/featured';
  youtube_streams_url = 'https://www.youtube.com/@7daysperformance/streams';
  instagram_url = 'https://www.instagram.com/7daysperformance/';
  android_app_link = 'https://play.google.com/store/apps/details?id=co.uk.daysperformance7.app';
  ios_app_link = 'https://apps.apple.com/us/app/7days-performance/id1455876956';
  universal_app_link = 'https://7daysperformance.co.uk/process-via/native-app?openbrowser=true';
}

export const projectData: ProjectDataModel = new ProjectData();
