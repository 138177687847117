import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { Form } from '@core/type/form.type';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateFieldsGeneratorService, monthsShort } from '@core/services/date-fields-generator/date-fields-generator.service';
import { NgFor, NgIf } from '@angular/common';

export class Settings {
  yearsLimit: number;
  userOld: number;
}


@Component({
  selector: 'app-date-of-birth-picker',
  templateUrl: './date-of-birth-picker.component.html',
  styleUrls: ['./date-of-birth-picker.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgFor, NgIf]
})

export class DateOfBirthPickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dob: string = null;
  @Output() dateOutput: EventEmitter<string> = new EventEmitter<string>();

  days: number[] = [];
  months: number[] = [];
  years: number[] = [];
  dateForm: FormGroup<Form<{ day: string; month: string; year: string; }>>;
  invalidDate = false;
  now = dayjs();
  oldError = false;
  settings: Settings = {
    userOld: 18,
    yearsLimit: 120
  };

  public _alive$ = new Subject<void>();

  constructor(
    private _dateFieldsGeneratorService: DateFieldsGeneratorService,
    private formBuilder: NonNullableFormBuilder
    ) {
    this.initForm();
    this.calcAllDaysInMonth(31);
    this.convertMonthsToNumbers(monthsShort);

    let currentYear = dayjs().year();

    for (let index = 0; index < this.settings.yearsLimit; index++) {
      this.years.push(currentYear);
      currentYear--;
    }
  }

  ngOnInit() {

    this.dateForm.valueChanges.pipe(takeUntil(this._alive$)).subscribe({
      next: (date) => {

        this.oldError = +date.year > this.now.year() - this.settings.userOld ? true : false;

        if (date.year && +date.year === this.now.year()) {
          const months = [];

          for (let index = 0; index < dayjs().month() + 1; index++) {
            months.push(index);
          }

          this.convertMonthsToNumbers(months);

          if (+this.dateForm.value.month + 1 > this.months.length) {
            this.dateForm.get('month').setValue(`${this.months.length - 1}`, { emitEvent: false });
          }

          if (this.dateForm.get('month').value && (+this.dateForm.get('month').value === this.now.month() + 1)) {
            this.days = [];

            for (let index = 0; index < dayjs().date() + 1; index++) {
              this.days.push(index);
            }
          }
        } else {
          this.convertMonthsToNumbers(monthsShort);
        }

        if (date.month !== '') {
          const year = date.year !== '' ? +date.year : this.years[this.years.length - 1];

          if (+date.month === this.now.month() + 1 && year === this.now.year()) {
            this.days = [];

            for (let index = 0; index < this.now.date() + 1; index++) {
              this.days.push(index);
            }

          } else {
            this.calcAllDaysInMonth(dayjs(`${year}-${+date.month + 1}`).daysInMonth());
          }

          if (+this.dateForm.get('day').value > this.days.length) {
            this.dateForm.get('day').setValue(`${this.days.length}`, { emitEvent: false });
          }

        }

        if (!this.oldError && this.dateForm.valid) {
          const newDate = dayjs(date.year + dayjs().month(+date.month).format('MM') + date.day);
          if (newDate.isValid()) {
            this.invalidDate = false;
            this.dateOutput.emit(newDate.format('YYYY-MM-DD'));
          } else {
            this.invalidDate = true;
            this.dateOutput.emit(newDate.format('YYYY-MM-DD'));
          }
        } else {
          this.dateOutput.emit(null);
        }
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    let dob = null;
    if (this.dob) {
      dob = dayjs(this.dob, 'YYYY-MM-DD');

      this.dateForm.patchValue({
        day: dob ? `${+dob.format('DD')}` : '',
        month: dob ? `${+dob.format('M') - 1}` : '',
        year: dob ? dob.format('YYYY') : ''
      }, { emitEvent: false });
    }

    if (this.dateForm.get('month').value && this.dateForm.get('year').value) {
      this.calcAllDaysInMonth(dayjs(`${this.dateForm.get('year').value}-${+this.dateForm.get('month').value + 1}`).daysInMonth());
    }
  }

  ngOnDestroy(): void {
    this._alive$.next();
    this._alive$.complete();
  }

  calcAllDaysInMonth(days: number) {
    this.days = [];

    for (let index = 1; index <= days; index++) {
      this.days.push(index);
    }

  }

  convertMonthsToNumbers(months: string[]) {
    this.months = [];

    months.forEach((months, i) => {
      this.months.push(i);
    });
  }

  formatForDropdown(type: string, num: number) {
    switch (type) {
      case 'day':
        if (num < 10) {
          return `0${num}`;
        } else {
          return num;
        }
      case 'month':
        return dayjs().month(num).format('MMM');
      case 'year':
        return num;
    }
  }

  private initForm() {
    this.dateForm = this.formBuilder.group({
      day: ['', [Validators.required]],
      month: ['', [Validators.required]],
      year: ['', [Validators.required]],
    });
  }

}
