import { SettingsModel } from '@core/models/settings.model';

export class SetSettingsData {
  static readonly type = '[Settings] SetSettingsData';
  constructor(public settings: SettingsModel | null) {}
}

export class UpdateInstallPrompt {
  static readonly type = '[Cart] UpdateInstallPrompt';
  constructor(public isVisible: boolean) {}
}
