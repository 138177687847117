import { Component, OnInit } from '@angular/core';
import {CustomerReview} from '@core/models/review.model';
import {HttpClient} from '@angular/common/http';
import {API_LIST} from '@core/services/web-api/api-list';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-reviews-google',
  templateUrl: './reviews-google.component.html',
  styleUrls: ['./reviews-google.component.scss']
})
export class ReviewsGoogleComponent implements OnInit {
  public reviews: CustomerReview[] = [];
  public isLoading = true;
  private colors: string[] = [
    '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
    '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
    '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
    '#ff5722', '#795548'
  ];

  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.httpClient.get(API_LIST.getCustomerReviews()).subscribe((data: CustomerReview[]) => {
      data.forEach((review: CustomerReview) => {
        let colorNumber = review.customer_name.length % this.colors.length;
        review.avatar_color = this.colors[colorNumber];
        review.text_safe = this.sanitizer.bypassSecurityTrustHtml(review.text);
      });

      this.reviews = data;
      this.isLoading = false;
    });
  }
}
