<div class="footer">
  <div class="footer__wrapper">
    <div class="footer__links">
      <a routerLink="/" class="footer__logo" title="">
        <img
          src="/assets/svg/logo.svg"
          alt="7days Performance"
          class="footer__logo-image">
      </a>
      <ul>
        <li><a routerLink="/terms-and-conditions">Terms &amp; Conditions</a></li>
        <li><a routerLink="/cookie-and-privacy-policy">Cookie &amp; Privacy Policy</a></li>
        <li><a routerLink="/website-terms">Website Terms</a></li>
<!--        <li><a routerLink="/streams">Streams</a></li>-->
        <li><a routerLink="/responsible-playing">Responsible Playing</a></li>
        <li><a routerLink="/reviews">Reviews</a></li>
        <li><a routerLink="/faq">FAQ</a></li>
        <li><a routerLink="/security">Security</a></li>
        <li>&nbsp;</li>
        <li><a routerLink="/about-us">Our Team</a></li>
      </ul>
    </div>
    <div class="footer__social">
      <div>
        <p><a href="mailto:hello@7daysperformance.co.uk" class="footer__social-email">hello&#64;7daysperformance.co.uk</a></p>
        <p class="footer__annotation--light">
          7days Performance - Technology and Marketing powered by
          <a href="https://7dayslabs.co.uk/" target="_blank" class="footer__annotation--light"><u>7days Labs</u></a>
        </p>
        <p class="footer__annotation--light">© All rights reserved</p>
      </div>
      <ul>
        <li>
          <a href="https://www.facebook.com/7daysPerformance/" target="_blank" rel="nofollow" class="footer__social-link" title="">
            <svg-icon name="facebook-new"></svg-icon>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/7daysperformance/" target="_blank" rel="nofollow" class="footer__social-link" title="">
            <svg-icon name="instagram-new"></svg-icon>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCxB-y6oGP1lSCHwoE2hKvmA" target="_blank" rel="nofollow" class="footer__social-link" title="">
            <svg-icon name="youtube-new"></svg-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
