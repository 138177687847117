<section class="section section_post-list min90height">
  <div class="container">
    <h2 class="not-found__title">404</h2>

    <aside class="alert alert_warn">
      <p class="alert__text">
        Sorry, but the page you were trying to view does not exist.
      </p>
    </aside>

    <div class="py-5 text-center">
      <a routerLink="/" class="button button_no-shadow button_blue order-details__button-semiblock">Go to homepage</a>
    </div>
  </div>
</section>

<hr class="line" />

<div class="container-wide py-5">
  <h2 class="section__header">UPCOMING COMPETITIONS</h2>

  <div class="row justify-content-center">
    <app-raffle-card *ngFor="let raffle of raffles" [raffle]="raffle"></app-raffle-card>
  </div>
</div>
