<ul class="card-pagination">
  <li class="card-pagination__item" *ngIf="currentPage > 1">
    <a [routerLink]="[urlPattern | sprintf:(currentPage - 1)]" [queryParamsHandling]="'merge'" class="card-pagination__link" title="">←</a>
  </li>
  <li class="card-pagination__item" *ngFor="let p of pages">
    <a [routerLink]="[urlPattern | sprintf:p]" class="card-pagination__link"
       [queryParamsHandling]="'merge'"
       [ngClass]="{'card-pagination__link_active': p === currentPage}"
       title="">{{ p }}</a>
  </li>
  <li class="card-pagination__item" *ngIf="currentPage < pageCount">
    <a [routerLink]="[urlPattern | sprintf:(1 + currentPage)]" [queryParamsHandling]="'merge'" class="card-pagination__link" title="">→</a>
  </li>
</ul>
