<section class="hero-slider" *ngIf="slides && slides.length">
  <div
    class="slider"
    (touchstart)="startSwipe($event)"
    (mousedown)="startSwipe($event)"
  >
    <div class="slide__gradient d-lg-none"></div>

    <button
      *ngIf="slides.length > 1"
      class="slider__button slider__button_prev"
      (click)="handleSlidePrev()"
    >
      <svg-icon name="arrow-left-7-d" class="slider__icon"></svg-icon>
    </button>

    <div class="slider__wrapper">
      <a
        *ngFor="let slide of slides; let index = index"
        class="slide"
        [ngClass]="{ slide_active: activeSlide === index }"
        [attr.href]="slide.ctaUrl"
        (click)="
          heroSectionEnterNow(slide.ctaUrl);
          navigateIfLocal(slide.ctaUrl, $event)
        "
      >
        <div class="slide__content">
          <ng-container [ngSwitch]="slide.type">
            <ng-template [ngSwitchCase]="MediaType.Image">
              <div
                class="slide__image"
                [class.slide__image_unloaded]="!slide.isSourceLoaded"
                [ngStyle]="{
                  'background-image': 'url(' + slide.sourceUrl + ')'
                }"
              >
                <div class="slide__overlay"></div>
              </div>
            </ng-template>

            <ng-template [ngSwitchCase]="MediaType.Video">
              <div
                class="slide__video"
                [class.slide__video_unloaded]="!slide.isSourceLoaded"
                [ngStyle]="{ 'background-image': 'url(' + slide.poster + ')' }"
              >
                <video
                  muted
                  playsinline
                  [class.slide__video_unloaded]="!slide.isSourceLoadedPhase2"
                  (loadeddata)="videoLoaded(slide)"
                  preload="auto"
                  [src]="slide.sourceUrl"
                  id="home-slider-video-{{ index }}"
                  [poster]="slide.poster"
                >
                  <source [src]="slide.sourceUrl" type="video/mp4" />
                </video>
                <div class="slide__overlay"></div>
              </div>
            </ng-template>
          </ng-container>

          <div class="slide__gradient d-lg-none"></div>

          <div class="slide__texts">
            <h2 class="slide__title" *ngIf="slide.line1">
              {{ slide.line1 }}
            </h2>
            <h3 class="slide__subtitle" *ngIf="slide.line2">
              {{ slide.line2 }}
            </h3>
            <div class="slider__enter-btn">
              <button class="d-flex align-items-center justify-content-center">
                <span>Enter now</span>
                <svg-icon name="ticket-small"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </a>
    </div>

    <button
      *ngIf="slides.length > 1"
      class="slider__button slider__button_next"
      (click)="handleSlideNext()"
    >
      <svg-icon name="arrow-left-7-d" class="slider__icon"></svg-icon>
    </button>
  </div>

  <div class="slider__button-spacer"></div>
</section>

<section class="hero-slider" *ngIf="!slides || !slides.length">
  <div class="slider">
    <div class="slider__wrapper">
      <div class="slide slide_active">
        <div class="slide__content">
          <div class="slide__image">
            <div class="slide__image-overlay"></div>
          </div>

          <div class="slide__gradient d-lg-none"></div>

          <div class="slide__texts">
            <h2 class="slide__title">
              <app-text-holder
                [desktopTexts]="[180, 130, 110]"
                [mobileTexts]="[100, 60]"
                [color]="'#ffffff'"
                opacity="0.2"
              ></app-text-holder>
            </h2>
            <h3 class="slide__subtitle">
              <app-text-holder
                [desktopTexts]="[110, 170, 130]"
                [mobileTexts]="[80, 70]"
                [color]="'#ffffff'"
                opacity="0.2"
              ></app-text-holder>
            </h3>
            <div class="slider__enter-btn">
              <button class="d-flex align-items-center justify-content-center">
                <span>
                  <app-text-holder
                    [desktopTexts]="[90]"
                    [mobileTexts]="[70]"
                    [color]="'#ffffff'"
                    opacity="0.2"
                  ></app-text-holder>
                </span>
                <svg-icon name="ticket-small"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="slider__button-spacer"></div>
</section>

<div class="info-bar">
  <div class="info-bar__counters">
    <div class="info-bar__counter">
      <div class="info-bar__icon"
        ><svg-icon name="winner" class="info-bar__icon-svg"></svg-icon
      ></div>
      <div class="info-bar__label">
        <div class="info-bar__value" *ngIf="winnersCountShow !== null">{{
          winnersCountShow | number: '1.0'
        }}</div>
        <div class="info-bar__value" *ngIf="winnersCountShow === null"
          ><app-text-holder [desktopTexts]="[60]"></app-text-holder
        ></div>
        <div class="info-bar__help">Prizes Won</div>
      </div>
    </div>

    <div class="info-bar__counter">
      <div class="info-bar__icon"
        ><svg-icon name="prize" class="info-bar__icon-svg"></svg-icon
      ></div>
      <div class="info-bar__label">
        <div class="info-bar__value" *ngIf="prizesCountShow !== null">{{
          prizesCountShow | currency: 'GBP' : 'symbol' : '1.0'
        }}</div>
        <div class="info-bar__value" *ngIf="prizesCountShow === null"
          ><app-text-holder
            [desktopTexts]="[200]"
            [mobileRatio]="0.5"
          ></app-text-holder
        ></div>
        <div class="info-bar__help">Given in Prizes</div>
      </div>
    </div>
  </div>

  <div
    class="info-bar__trust"
    [class.info-bar__trust-worldwide]="geoVersion !== 'uk'"
  >
    <div class="info-bar__worldwide-inner" [class.d-none]="geoVersion === 'uk'">
      <div class="worldwide" (click)="openWorldwideDialog()">
        <svg-icon
          name="world-stroke"
          class="worldwide__world"
          fill="none"
        ></svg-icon>
        <span class="worldwide__label"> Worldwide shipping </span>

        <span class="worldwide__info-holder">
          <svg-icon
            name="info-icon"
            class="worldwide__info"
            fill="none"
          ></svg-icon>
        </span>
      </div>

      <div class="worldwide__label2"
        >Read <a routerLink="/worldwide-shipping"><b>Shipping rules</b></a></div
      >
    </div>

    <div
      class="d-lg-none info-bar__trustpilot-mobile"
      [class.d-none]="geoVersion !== 'uk'"
    >
      <app-trustpilot
        [config]="trustpilotSmallConfig"
        [height]="'20px'"
      ></app-trustpilot>
    </div>

    <div class="pt-4 info-bar__trustpilot">
      <app-trustpilot [config]="trustpilotConfig"></app-trustpilot>
    </div>

    <div class="info-bar__payments">
      <div class="info-bar__payment">
        <svg-icon [name]="paymentIcon"></svg-icon>
      </div>
      <div class="info-bar__payment">
        <svg-icon name="paypal-new"></svg-icon>
      </div>
    </div>
  </div>
</div>
