import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgPipesModule } from 'ngx-pipes';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF, faTwitter, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoundPipe } from '@custom-pipes/round/round.pipe';
import { OrdinalPipe } from '@custom-pipes/ordinal/ordinal.pipe';

@NgModule({
  exports: [
    CommonModule,
    NgPipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatDialogModule,

    RoundPipe,
    OrdinalPipe,
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatDialogModule,

    RoundPipe,
    OrdinalPipe,
  ],
  declarations: [
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      // fab
      faFacebookF,
      faTwitter,
      faFacebookMessenger,
    );
  }
}
