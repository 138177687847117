import { Injectable } from '@angular/core';

export class Month {
  label: string;
  value: number;
}
export const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

@Injectable({
  providedIn: 'root'
})
export class DateFieldsGeneratorService {
  constructor() { }

  static loadYears(minYearDifference: number, maxYearDifference: number): number[] {
    const currentYear = (new Date).getFullYear();
    const minYear = currentYear - minYearDifference;
    const maxYear = currentYear - maxYearDifference;

    const data: number[] = [];
    for (let y = maxYear; y >= minYear; y-- ) {
      data.push(y);
    }

    return data;
  }

  static loadMonths(type = 'short'): Month[] {

    const months: string[] = type == 'long' ? monthsLong : monthsShort;

    const data: Month[] = [];
    for (let m = 1; m <= 12; m++ ) {
      data.push({label: months[m - 1], value: m});
    }

    return data;
  }

  static loadMonthDays(year = -1, monthNumber = -1): number[] {
    const days = [];
    let daysNo;

    if (year == -1 || monthNumber == -1) {
      daysNo = 31;
    } else {
      daysNo = new Date(year, monthNumber, 0).getDate();
    }

    for (let i = 1; i <= daysNo; i++) {
      days.push(i);
    }

    return days;
  }
}
