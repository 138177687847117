import { Injectable } from '@angular/core';
import { SettingsModel } from '@core/models/settings.model';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import {
  SetSettingsData,
  UpdateInstallPrompt
} from '@store/settings/settings.actions';

export class SettingsStateModel {
  settings: SettingsModel | null = null;
  isInitiated = false;
  installPrompt = true;
}

@State<SettingsStateModel>({
  name: 'settings',
  defaults: new SettingsStateModel()
})
@Injectable()
export class SettingsState implements NgxsOnInit {
  @Selector()
  static settings(state: SettingsStateModel): SettingsModel | null {
    return state.settings;
  }

  @Selector()
  static paymentsDisabledMessageHomepage(state: SettingsStateModel): string {
    return state.settings
      ? state.settings.payments_disabled_message_homepage
      : '';
  }

  @Selector()
  static totalPrizesValue(state: SettingsStateModel): string | null {
    return state.settings ? state.settings.total_prizes_value : '';
  }

  @Selector()
  static totalWinners(state: SettingsStateModel): string | null {
    return state.settings ? state.settings.total_winners : '';
  }

  @Selector()
  static getAppBanner(state: SettingsStateModel): string | null {
    return state.settings ? state.settings.app_homepage_banner : null;
  }

  @Selector()
  static state(state: SettingsStateModel): SettingsStateModel {
    return state;
  }

  @Selector()
  static installPrompt(state: SettingsStateModel): boolean {
    return state.installPrompt;
  }

  ngxsOnInit(ctx: StateContext<SettingsStateModel>): void {
    ctx.patchState({
      isInitiated: true
    });
  }

  @Action(SetSettingsData)
  setCartData(
    ctx: StateContext<SettingsStateModel>,
    action: SetSettingsData
  ): void {
    ctx.patchState({
      settings: !action.settings ? null : action.settings
    });
  }

  @Action(UpdateInstallPrompt)
  updateInstallPrompt(
    ctx: StateContext<SettingsStateModel>,
    action: UpdateInstallPrompt
  ): void {
    ctx.patchState({
      installPrompt: action.isVisible
    });
  }
}
