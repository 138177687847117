import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FacebookTrackingService } from './facebook-tracking.service';
import { SnapchatTrackingService } from './snapchat-tracking.service';

@Injectable()
export class TrackingInterceptor implements HttpInterceptor {

  constructor(
    private facebookTrackingService: FacebookTrackingService,
    private snapchatTrackingService: SnapchatTrackingService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: Record<string, string> = {};

    headers = this.facebookTrackingService.addHeaders(headers);
    headers = {
      ...headers,
      ...this.snapchatTrackingService.addHeaders(headers)
    };

    if (Object.keys(headers).length > 0) {
      req = req.clone({
        setHeaders: headers
      });
    }

    return next.handle(req);
  }
}
