import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { TextHolderComponent } from '@components/text-holder/text-holder.component';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sticky-nav',
  templateUrl: './sticky-nav.component.html',
  styleUrls: ['./sticky-nav.component.scss'],
  imports: [CommonModule, SvgIconComponent, TextHolderComponent],
  standalone: true
})
export class StickyNavComponent
  implements AfterViewInit, OnInit, OnDestroy, OnChanges
{
  @Input() items: ItemTab[];
  @Input() buttons: ItemTab[] = [];
  @Input() isLoading = false;
  @Input() type: 'item' | 'raffle' = 'raffle';
  @ViewChild('navInnerRef') navInnerRef: ElementRef<HTMLElement> | undefined;
  @ViewChild('navSwiperRef') navSwiperRef: ElementRef<HTMLElement> | undefined;
  @ViewChild('navPositionRef') navPositionRef:
    | ElementRef<HTMLElement>
    | undefined;

  public navAbsoluteTop = 0;
  public navCssPosition = 'absolute';
  public isNavShort = false;
  public activeItem: ItemTab | null = null;
  public _alive$ = new Subject<void>();
  private lastCursorNavPosition = -1;
  private lastCursorDownNavPosition = -1;
  private isForcedSectionScrolling = false;

  ngOnInit(): void {
    this.installPageScrollListener();
    this.installNavScrollListener();
  }

  ngAfterViewInit(): void {
    if (GonativeService.isIos()) {
      this.navInnerRef.nativeElement.style.overflow = 'auto';
    }
  }

  ngOnChanges(): void {
    if (this.items && this.items.length > 0) {
      this.items.sort((a, b) => (a.id > b.id ? 1 : -1));
      this.activeItem = this.items[0];
    }
  }

  ngOnDestroy(): void {
    this._alive$.next();
    this._alive$.complete();
  }

  handleItemNavClick(event, item): void {
    const cursorPosition = this.getCursorRelativePositionFromEvent(event);
    const scrollRelative = cursorPosition - this.lastCursorDownNavPosition;

    if (Math.abs(scrollRelative) > 5) {
      return;
    }

    this.activateSection(item);
  }

  activateSection(item: ItemTab): void {
    this.activeItem = item;
    this.scrollNavItemIntoView(item);
    this.scrollToSection(item);
  }

  scrollNavItemIntoView(item: ItemTab): void {
    const itemElem = document.getElementById(
      this.getNavElemByItem(item)
    ).parentElement;
    const navMenuWidth = this.navInnerRef.nativeElement.offsetWidth;
    const rect = itemElem.getBoundingClientRect();

    if (rect.left < 0) {
      this.navInnerRef.nativeElement.scrollTo({
        left: itemElem['offsetLeft'],
        behavior: 'smooth'
      });
    } else if (rect.left + itemElem['offsetWidth'] > navMenuWidth) {
      const scrollPos =
        itemElem['offsetLeft'] - navMenuWidth + itemElem['offsetWidth'];
      this.navInnerRef.nativeElement.scrollTo({
        left: scrollPos,
        behavior: 'smooth'
      });
    }
  }

  scrollToSection(item: ItemTab): void {
    const sectionElem = document.getElementById(item.idAttr);
    const mainMenuHeight =
      document.querySelector('.header-container')['offsetHeight'];
    const navMenuHeight = this.navInnerRef.nativeElement.offsetHeight;
    const additionalTopSpace = mainMenuHeight + navMenuHeight;

    const y =
      sectionElem.getBoundingClientRect().top +
      window.pageYOffset -
      additionalTopSpace +
      3;
    window.scrollTo({ top: y, behavior: 'smooth' });

    this.isForcedSectionScrolling = true;

    setTimeout(() => {
      this.isForcedSectionScrolling = false;
    }, 1500);
  }

  installPageScrollListener() {
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this._alive$))
      .subscribe(() => {
        this.keepNavigationInViewport();
        this.doSectionDetection();
      });

    fromEvent(window, 'resize')
      .pipe(takeUntil(this._alive$))
      .subscribe(() => {
        this.keepNavigationInViewport();
        this.doSectionDetection();
      });
  }

  doSectionDetection(): void {
    if (this.isForcedSectionScrolling) {
      return;
    }

    if (!document.getElementById('navigation-wrapper')) {
      // if it's deteached from DOMTree
      return;
    }

    if (!this.navInnerRef) {
      return;
    }

    const mainMenuHeight =
      document.querySelector('.header-container')['offsetHeight'];
    const stickyNavHeight = this.navInnerRef.nativeElement['offsetHeight'];
    const topCheckpoint = mainMenuHeight + stickyNavHeight;

    for (const item of this.items) {
      const elem = document.getElementById(item.idAttr);

      if (!elem) {
        continue;
      }

      const rect = elem.getBoundingClientRect();

      if (rect.top + rect.height - 3 >= topCheckpoint) {
        if (item !== this.activeItem) {
          this.activeItem = item;
          this.scrollNavItemIntoView(item);
        }

        break;
      }
    }
  }

  keepNavigationInViewport(): void {
    if (!this.navPositionRef) {
      return;
    }

    const rect = this.navPositionRef.nativeElement.getBoundingClientRect();
    const navScrolledRelative = rect.top;

    const mainMenuHeight =
      document.querySelector('.header-container')['offsetHeight'];
    const compsWrapper = document.getElementById('navigation-wrapper');

    // if it's deteached from DOMTree
    if (!compsWrapper) {
      return;
    }

    const compsWrapperHeight = compsWrapper['offsetHeight'];
    const compsWrapperRect = compsWrapper.getBoundingClientRect();
    const stickyNavHeight = this.navInnerRef.nativeElement['offsetHeight'];

    this.isNavShort = mainMenuHeight > navScrolledRelative;

    if (
      -1 * compsWrapperRect.top >
      compsWrapperHeight - mainMenuHeight - stickyNavHeight
    ) {
      this.navAbsoluteTop = compsWrapperHeight - stickyNavHeight;
      this.navCssPosition = 'absolute';
    } else if (mainMenuHeight > navScrolledRelative) {
      this.navAbsoluteTop = mainMenuHeight;
      this.navCssPosition = 'fixed';
    } else {
      this.navAbsoluteTop = 0;
      this.navCssPosition = 'absolute';
    }
  }

  installNavScrollListener() {
    fromEvent(window, 'mousemove')
      .pipe(takeUntil(this._alive$))
      .subscribe(event => {
        this.doScrollingNav(event);
      });

    fromEvent(window, 'touchmove')
      .pipe(takeUntil(this._alive$))
      .subscribe(event => {
        this.doScrollingNav(event);
      });

    fromEvent(window, 'mouseup')
      .pipe(takeUntil(this._alive$))
      .subscribe(event => {
        this.stopScrollingNav(event);
      });

    fromEvent(window, 'touchend')
      .pipe(takeUntil(this._alive$))
      .subscribe(event => {
        this.stopScrollingNav(event);
      });

    fromEvent(window, 'touchcancel')
      .pipe(takeUntil(this._alive$))
      .subscribe(event => {
        this.stopScrollingNav(event);
      });
  }

  startScrollingNav(event): boolean {
    this.lastCursorNavPosition = this.getCursorRelativePositionFromEvent(event);
    this.lastCursorDownNavPosition = this.lastCursorNavPosition;

    return false;
  }

  doScrollingNav(event): void {
    if (this.lastCursorNavPosition < 0) {
      return;
    }

    const cursorPosition = this.getCursorRelativePositionFromEvent(event);
    const scrollRelative = cursorPosition - this.lastCursorNavPosition;
    this.lastCursorNavPosition = cursorPosition;
    this.navInnerRef.nativeElement.scrollLeft =
      this.navInnerRef.nativeElement.scrollLeft - scrollRelative;
  }

  stopScrollingNav(event): boolean {
    if (this.lastCursorNavPosition < 0) {
      return;
    }

    this.lastCursorNavPosition = -1;

    setTimeout(() => {
      this.lastCursorDownNavPosition = -1;
    }, 5);

    return false;
  }

  getNavElemByItem(item: ItemTab): string {
    return 'nav-item-' + item.idAttr;
  }

  private getCursorRelativePositionFromEvent(e): number {
    if (e.touches && e.touches.length === 1) {
      return e.touches[0].clientX;
    }

    if (e.changedTouches && e.changedTouches.length === 1) {
      return e.changedTouches[0].clientX;
    }

    return e.clientX;
  }
}

export class ItemTab {
  idAttr: string;
  id: number;
  name: string;
  fullName: string;
  icon?: string;
}
