import { NgModule } from '@angular/core';
import { SharedModule } from '@project/shared/shared.module';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { RaffleCardComponent } from '../../components/raffle-card/raffle-card.component';
import { EmailSubscriptionComponent } from './components/email-subscription/email-subscription.component';
import { CardPaginationComponent } from '@components/card-pagination/card-pagination.component';
import { TextHolderComponent } from '@components/text-holder/text-holder.component';
import { TopAvailabilityMessageComponent } from '@project/components/top-availability-message/top-availability-message.component';
import { HeaderShortComponent } from './components/header-short/header-short.component';
import { InfoBarBrandedComponent } from './components/info-bar-branded/info-bar-branded.component';
import { InstallPromptComponent } from '@components/install-prompt/install-prompt.component';
import { FixOnChangesDirective } from './directives/fix-on-changes/fix-on-changes.directive';
import { OnlyNumbersDirective } from './directives/only-numbers/only-numbers.directive';
import { SimpleSprintfPipe } from '@custom-pipes/simple-sprintf/simple-sprintf.pipe';
import { SafeHtmlPipe } from '@custom-pipes/safe-html/safe-html.pipe';
import { TicketCardComponent } from '@components/ticket-card/ticket-card.component';
import { DateOfBirthPickerComponent } from '@components/date-of-birth-picker/date-of-birth-picker.component';
import { NgOptimizedImage } from '@angular/common';
import { SwitchMultiCasePipe } from '@custom-pipes/switch-multi-case/switch-multi-case.pipe';
import { DatetimeModule } from '@shared/datetime/datetime.module';
import { NotFoundComponent } from '@project/components/not-found/not-found.component';
import { HeaderAppBannerComponent } from '@components/header-app-banner/header-app-banner.component';

@NgModule({
    exports: [
        SharedModule,
        SvgIconComponent,
        HeaderComponent,
        FooterComponent,
        InfoBarComponent,
        RaffleCardComponent,
        EmailSubscriptionComponent,
        CardPaginationComponent,
        TextHolderComponent,
        SimpleSprintfPipe,
        SwitchMultiCasePipe,
        FixOnChangesDirective,
        OnlyNumbersDirective,
        TopAvailabilityMessageComponent,
        HeaderShortComponent,
        InfoBarBrandedComponent,
        InstallPromptComponent,
        HeaderAppBannerComponent,
        TicketCardComponent,
        DateOfBirthPickerComponent,
        DatetimeModule,
        NotFoundComponent,
    ],
    imports: [
        SharedModule,
        NgOptimizedImage,
        DatetimeModule,
        SvgIconComponent,
        CardPaginationComponent,
        InstallPromptComponent,
        HeaderAppBannerComponent,
        DateOfBirthPickerComponent,
        TextHolderComponent,
        TicketCardComponent,
        TopAvailabilityMessageComponent,
        SafeHtmlPipe,
        SimpleSprintfPipe,
        SwitchMultiCasePipe,
        NotFoundComponent,
        RaffleCardComponent
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        InfoBarComponent,
        EmailSubscriptionComponent,
        FixOnChangesDirective,
        OnlyNumbersDirective,
        HeaderShortComponent,
        InfoBarBrandedComponent,
    ]
})
export class TemplateModule {
    constructor() {
    }
}
