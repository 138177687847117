import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SimpleSprintfPipe } from '@custom-pipes/simple-sprintf/simple-sprintf.pipe';

@Component({
  selector: 'app-card-pagination',
  templateUrl: './card-pagination.component.html',
  styleUrls: ['./card-pagination.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, SimpleSprintfPipe]
})
export class CardPaginationComponent implements OnChanges {
  public pages: number[];

  @Input() pageCount = 0;
  @Input() urlPattern = '';
  @Input() currentPage = 0;

  constructor(private router: Router) { }

  ngOnChanges(): void {
    this.pageCount = Number(this.pageCount);

    this.pages = Array(this.pageCount).fill(null).map((item, index) => index + 1);
  }
}
