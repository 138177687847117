import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-winners-section',
  templateUrl: './winners-section.component.html',
  styleUrls: ['./winners-section.component.scss']
})
export class WinnersSectionComponent {
  @Input() winners: { url: string, alt: string }[];
  @Input() ctaLink: string[];
}
