import { NgModule } from '@angular/core';
import { ReviewsTrustpilotComponent } from './components/reviews-trustpilot/reviews-trustpilot.component';
import { ReviewsGoogleComponent } from './components/reviews-google/reviews-google.component';
import { TemplateModule } from '@project/shared/template/template.module';
import { SwiperModule } from 'swiper/angular';
import { TrustpilotWidgetComponent } from './components/trustpilot-widget/trustpilot-widget.component';
import { TrustpilotComponent } from '@components/trustpilot/trustpilot.component';

@NgModule({
  declarations: [
    ReviewsTrustpilotComponent,
    ReviewsGoogleComponent,
    TrustpilotWidgetComponent,
  ],
  exports: [
    ReviewsTrustpilotComponent,
    ReviewsGoogleComponent,
    TrustpilotComponent,
    TrustpilotWidgetComponent,
  ],
  imports: [
    TemplateModule,
    SwiperModule,
    TrustpilotComponent,
  ]
})
export class ReviewsSharedModule { }
