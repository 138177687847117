import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RaffleInstantType } from '@core/models/raffle-instant-type.enum';

@Component({
  selector: 'app-raffle-instant-logo',
  templateUrl: './raffle-instant-logo.component.html',
  styleUrls: ['./raffle-instant-logo.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class RaffleInstantLogoComponent implements OnChanges {
  @Input() instantType: RaffleInstantType;

  readonly RaffleInstantType = RaffleInstantType;

  imgSrc = '';
  imgTitle = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.instantType) {
      this.imgSrc = `/assets/images/instant-logos/${changes.instantType.currentValue}-logo.svg`;
      this.imgTitle = `Instant ${changes.instantType.currentValue} logo`;
    }
  }
}
