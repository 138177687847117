import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Cart } from '@core/models/cart.model';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { Select } from '@ngxs/store';
import { CartState } from '@store/cart/cart.state';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagePopupService } from '@core/services/message-popup/message-popup.service';
import { SettingsState } from '@store/settings/settings.state';
import { MatDialog } from '@angular/material/dialog';


type SpecialMenuItem = {
  label: string;
  link: string;
  start: string;
  end: string;
};

const SPECIAL_MENU_ITEMS: SpecialMenuItem[] = [
  {
    label: 'Jackpot Prize',
    link: '/product/win-10-chances-300325',
    start: '2025-03-20T00:00:00Z',
    end: '2025-03-30T20:45:00Z',
  },
  {
    label: 'Property',
    link: '/product/win-lodge-urus-060425',
    start: '2025-03-30T20:45:01Z',
    end: '2025-04-06T23:59:59Z',
  }
];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() showInstallPrompt = true;
  public alive$: Subject<void> = new Subject();
  public timeout: number;
  public isSubHeaderVisible: boolean;
  public isMobileMenuVisible: boolean;
  public cart: Cart | null;
  public appHomepageBannerText: string | null;
  public isNativeAppVisible = false;
  public isIosApp = false;
  public isAndroidApp = false;
  public isShort = false;
  public isActivePrompt = false;
  public isBudgetLeft = false;
  public remainingLimit = false;
  public activeSpecialMenuItem: SpecialMenuItem | null = null;

  @Select(CartState.cart) cart$: Observable<Cart | null>;
  @Select(SettingsState.getAppBanner) appHomepageBanner$: Observable<string | null>;

  constructor(
    private window: Window,
    public messagePopupService: MessagePopupService,
    public dialog: MatDialog
  ) {
    this.appHomepageBanner$.pipe(takeUntil(this.alive$)).subscribe((text: string | null) => {
      this.appHomepageBannerText = text;
    });
  }

  ngOnInit(): void {
    this.isNativeAppVisible = GonativeService.isGonative();
    this.isIosApp = GonativeService.isIos();
    this.isAndroidApp = GonativeService.isAndroid();
    this.checkHeaderVisability();


    fromEvent(this.window, 'scroll').pipe(
      takeUntil(this.alive$)
    ).subscribe(() => {
      if (this.timeout) {
        this.window.cancelAnimationFrame(this.timeout);
      }

      this.timeout = this.window.requestAnimationFrame(() => {
        this.checkHeaderVisability();
      });

      this.isShort = window.scrollY > 150;
    });

    this.updateSpecialMenuItemVisibility();
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }

  checkHeaderVisability() {
    const headerPosition = window.pageYOffset;
    this.isSubHeaderVisible = headerPosition > 200;
  }

  handleMobileMenuOpen() {
    this.isMobileMenuVisible = true;
  }

  handleMobileMenuClose() {
    this.isMobileMenuVisible = false;
  }

  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }

  goBack(event) {
    event.preventDefault();
    history.back();
  }

  handlePrompt(event) {
    this.isActivePrompt = event;
  }


  private updateSpecialMenuItemVisibility(): void {
    const now = new Date();
    const nowUK = new Date(now.toLocaleString("en-US", { timeZone: "Europe/London" })).getTime();

    this.activeSpecialMenuItem = SPECIAL_MENU_ITEMS.find(item => {
      const start = new Date(item.start).getTime();
      const end = new Date(item.end).getTime();
      return nowUK >= start && nowUK <= end;
    }) || null;
  }
}
