export interface BadgeData {
  leftText?: string;
  rightText?: string;
  counterLength: number;
  counterType: BadgeCounterType;
  type: BadgeType;
}

export enum BadgeType {
  Loss = 'loss',
  Win = 'win',
  Reveal = 'reveal',
  Revealed = 'revealed'
}

export enum BadgeCounterType {
  Default = 'default',
  Scratch = 'scratch'
}
