import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AVAILABLE_COUNTRIES_MAP } from '@components/install-prompt/constants/prompt-available-countries.constant';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { DeviceType } from '@core/models';
import { CountriesService } from '@core/services/countries/countries.service';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { ProjectDataService } from '@core/services/project-data/project-data.service';
import { Select, Store } from '@ngxs/store';
import { UpdateInstallPrompt } from '@store/settings/settings.actions';
import { SettingsState } from '@store/settings/settings.state';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-install-prompt',
  templateUrl: './install-prompt.component.html',
  styleUrls: ['./install-prompt.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ height: 0, transform: 'translateY(-100%)' }),
        animate(500, style({ height: '73.5px', transform: 'translateY(0)' }))
      ]),
      transition(':leave', [animate(500, style({ height: 0 }))])
    ])
  ],
  standalone: true,
  imports: [AsyncPipe, NgIf, SvgIconComponent, RouterModule]
  // TODO: Implement on push strategy
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallPromptComponent implements OnInit {
  @Output() statusPrompt = new EventEmitter<boolean>();

  title = null;
  project = null;
  isAndroid = true;
  installPromptVisible$: Observable<boolean>;
  isTargetBlank = true;
  deviceType: DeviceType;

  @Select(SettingsState.installPrompt) installPrompt$: Observable<boolean>;

  constructor(
    private countriesService: CountriesService,
    private changeDetectorRef: ChangeDetectorRef,
    private projectDataService: ProjectDataService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit() {
    this.title = this.projectDataService.get('prompt_title');
    this.project = this.projectDataService.get('project');
    this.isAndroid = GonativeService.isAndroid();
    this.installPromptVisible$ = combineLatest([
      this.installPrompt$,
      this.countriesService.visitorCountry$
    ]).pipe(
      map(([prompt, country]) => {
        const deviceType = GonativeService.getDeviceType();
        const isVisible =
          prompt &&
          !GonativeService.isGonative() &&
          AVAILABLE_COUNTRIES_MAP.has(deviceType) &&
          AVAILABLE_COUNTRIES_MAP.get(deviceType).includes(country.country_iso);
        setTimeout(() => {
          this.statusPrompt.emit(isVisible);
          this.changeDetectorRef.detectChanges();
        }, 300);
        return isVisible;
      })
    );
  }

  hidePrompt(): void {
    this.store.dispatch(new UpdateInstallPrompt(false));
    this.statusPrompt.emit(false);
  }

  getApp() {
    this.hidePrompt();
    if (this.isAndroid && this.project === 'ukcarp') {
      this.router.navigate(['/install-app-for-android']);
    } else {
      const url = this.isAndroid
        ? GonativeService.getAndroidAppLink()
        : GonativeService.getiOSAppLink();
      window.open(url, '_blank');
    }
  }
}
