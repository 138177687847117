<div class="form-group" [formGroup]="dateForm">
  <div class="form-group__col">
    <div class="select-wrap">
      <select name="day" id="day" class="form-group__control" formControlName="day">
        <option value="" hidden>Day</option>
        <option *ngFor="let d of days" [value]="d">{{formatForDropdown('day', d)}}</option>
      </select>
    </div>
  </div>

  <div class="form-group__col">
    <div class="select-wrap">
      <select name="month" id="month" class="form-group__control" formControlName="month">
        <option value="" hidden>Month</option>
        <option *ngFor="let m of months" [value]="m">{{formatForDropdown('month', m)}}</option>
      </select>
    </div>
  </div>

  <div class="form-group__col">
    <div class="select-wrap">
      <select name="year" id="year" class="form-group__control" formControlName="year">
        <option value="" hidden>Year</option>
        <option *ngFor="let y of years" [value]="y">{{formatForDropdown('year', y)}}</option>
      </select>
    </div>
  </div>
  <span class="form-group__hint form-group__hint--error" *ngIf="oldError">We are sorry, but you must be at least 18 years old to register.</span>
  <span class="form-group__hint form-group__hint--error" *ngIf="invalidDate">Invalid date of birth</span>
</div>