import { Injectable } from '@angular/core';
import { BaseTrackingService } from './base-tracking.service';

@Injectable({ providedIn: 'root' })
export class FacebookTrackingService extends BaseTrackingService {
  addHeaders(headers: Record<string, string>): Record<string, string> {
    const fbc = this.getCookie('_fbc');
    if (fbc) {
      headers['X-Fbc'] = fbc;
    }

    return headers;
  }
}
