import { Pipe, PipeTransform } from '@angular/core';
import {SimpleSprintfService} from '@core/services/simple-sprintf/simple-sprintf.service';

// @TODO: remove any, we have exact waiting value
@Pipe({
  name: 'sprintf',
  standalone: true
})
export class SimpleSprintfPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return SimpleSprintfService.sprintf(value, ...args);
  }
}
