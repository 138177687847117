<section class="why">
  <div class="container">
    <h2 class="section-title section-title--competition section-title--white">Why choose us?</h2>

    <div class="why__steps">

      <div class="why__step">
        <div class="why__icon">
          <img src="/assets/svg-icons/limited.svg" alt="">
        </div>
        <div class="why__label">
          <div class="why__title">Great Odds</div>
          <div class="why__subtitle">Limited tickets in<br class="d-none d-lg-block" /> each draw</div>
        </div>
      </div>

      <div class="why__step">
        <div class="why__icon">
          <img src="/assets/svg-icons/entries.svg" alt="">
        </div>
        <div class="why__label">
          <div class="why__title">Transparent</div>
          <div class="why__subtitle">We show the number of entries in every draw</div>
        </div>
      </div>

      <div class="why__step">
        <div class="why__icon">
          <img src="/assets/svg-icons/winner-blue.svg" alt="">
        </div>
        <div class="why__label">
          <div class="why__title">Guaranteed</div>
          <div class="why__subtitle">Guaranteed winner even if we don't sell out</div>
        </div>
      </div>

    </div>
  </div>
</section>
