import { NgModule } from '@angular/core';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { TemplateModule } from '@project/shared/template/template.module';
import { ReviewsSharedModule } from '@project/shared/reviews-shared/reviews-shared.module';
import { WinnersSectionComponent } from './components/winners-section/winners-section.component';
import { HowToPlayComponent } from './components/how-to-play/how-to-play.component';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { WhyChooseUsComponent } from './components/why-choose-us/why-choose-us.component';
import { PaymentSystemsComponent } from '@components/payment-systems/payment-systems.component';
import { StickyNavComponent } from '@components/sticky-nav/sticky-nav.component';

@NgModule({
  declarations: [
    HomeComponent,
    WinnersSectionComponent,
    HowToPlayComponent,
    HomeSliderComponent,
    DownloadAppComponent,
    WhyChooseUsComponent,
  ],
  imports: [
    TemplateModule,
    HomeRoutingModule,
    ReviewsSharedModule,
    PaymentSystemsComponent,
    StickyNavComponent,
  ]
})
export class HomeModule { }
