<section class="download">
  <div class="container">
    <h2 class="download__header">Download our app</h2>
    <p class="download__desc">Get exclusive offers only available to our app users.</p>

    <img class="d-none d-lg-inline-block download__iphone" src="/assets/images/download/iPhone_13_pro-lg.png" alt="#">
    <img class="d-lg-none download__iphone" src="/assets/images/download/iPhone_13_pro.png" alt="#">

    <div class="download__bg"></div>

    <div class="download__buttons">
      <a href="https://apps.apple.com/gb/app/7days-performance/id1455876956" target="_blank">
        <svg-icon class="store-button store-button--apple" name="app-store"></svg-icon>
      </a>
      <a href="https://play.google.com/store/apps/details?id=co.uk.daysperformance7.app" target="_blank">
        <svg-icon class="store-button store-button-google" name="google-store"></svg-icon>
      </a>
    </div>
  </div>
</section>
