import {
  CurrencyPipe,
  LowerCasePipe,
  NgIf,
  NgOptimizedImage,
  NgStyle,
  NgSwitch,
  NgSwitchCase
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { DrawAvailability, RaffleSimple } from '@core/models/raffle.model';
import { DateFormatterService } from '@core/services/date-formatter/date-formatter.service';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { RaffleInstantLogoComponent } from '@components/raffle-instant-badge/raffle-instant-logo.component';
import { DateUnifierPipe } from '@shared/datetime/custom-pipes/date-unifier/date-unifier.pipe';
import { TzDatePipe } from '@shared/datetime/custom-pipes/tz-date/tz-date.pipe';
import { UkTimezonePipe } from '@shared/datetime/custom-pipes/uk-timezone/uk-timezone.pipe';
import { RaffleInstantBadgePageType } from '@core/models/raffle-instant-type.enum';

@Component({
  selector: 'app-raffle-card',
  templateUrl: './raffle-card.component.html',
  styleUrls: ['./raffle-card.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgStyle,
    NgSwitch,
    NgSwitchCase,
    CurrencyPipe,
    LowerCasePipe,
    DateUnifierPipe,
    UkTimezonePipe,
    TzDatePipe,
    RouterModule,
    SvgIconComponent,
    ReactiveFormsModule,
    FormsModule,
    NgOptimizedImage,
    RaffleInstantLogoComponent
  ]
})
export class RaffleCardComponent implements OnInit {
  @Input() raffle: RaffleSimple | null;
  @Input() version: string;

  readonly raffleInstantBadgePageType = RaffleInstantBadgePageType;

  public price: string;
  public originalPrice: string;
  public currency: string;
  public min: 0;
  public sliderMaxTickets: 100;
  public sliderValue;
  public sliderHalfOfValue = 50;
  public drawTimeVersion = '';
  public isInApp = false;
  public isMixed: boolean;
  public bottomInfoText = '';
  public bottomInfoStyle = '';

  constructor() {}

  get isInstantWin(): boolean {
    return (
      this.raffle.instant_win_count > 0 &&
      this.raffle.category_ids.includes('instant-wins')
    );
  }

  ngOnInit(): void {
    this.isInApp = GonativeService.isGonative();

    this.updateDetails();
  }

  updateDetails() {
    this.drawTimeVersion = DateFormatterService.drawRelativeTimeVersion(
      this.raffle.result_at
    );
    this.isMixed =
      DateFormatterService.getDateDiff(
        this.raffle.end_at,
        this.raffle.result_at,
        'mins'
      ) > 20 && this.raffle.is_open;
    this.price = this.raffle.offers[0].price;
    this.originalPrice = this.raffle.offers[0].original_price_value_frontend;
    this.currency = this.raffle.offers[0].currency;
    this.sliderValue = Math.floor(
      (this.raffle.current_entries / this.raffle.max_entries) * 100
    );

    this.bottomInfoStyle = '';

    if (
      this.raffle.purchase_available_type === DrawAvailability.AppOnly &&
      !this.isInApp
    ) {
      this.bottomInfoText = 'App Exclusive';
    } else if (
      this.raffle.purchase_available_type === DrawAvailability.BrowserOnly &&
      this.isInApp
    ) {
      this.bottomInfoText = 'Only in browser';
    } else if (
      !this.raffle.is_without_draw &&
      this.raffle.draw_method === 'auto'
    ) {
      this.bottomInfoText = 'Automated Draw';
      this.bottomInfoStyle = 'blue-border';
    }
  }
}
